<template>
   <v-card :color=" theme ? grey1 :  'primary'" flat class="white--text">
    <v-container>
      <v-row>
        <v-col>
          <v-card flat color="transparent" class="mt-10">
            <v-card-title>
              <span class="font-weight-bold white--text">How to Contribute!</span>
            </v-card-title>
            <v-card-text class="mt-5 white--text">
              haq is a platform which we hope can be as collaborative as possible. Whether  you’re interested in making challenges and problems, moderating our Discord server, have found a bug that you would like to report, or you just found the project cool and would like to get in touch, feel free to connect with us!
            </v-card-text>
            <v-card-actions class="mt-5 ml-2">
              <v-btn class="pa-5 white--text" color="black" to="/contribute"> Contribute Now </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col>
          <div class="ml-5 mt-10">
            <v-img class="" max-height="500" width="300px" :src="theme ? require('@/assets/images/contribute-dark.svg') : require('@/assets/images/contribute.svg')"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'Contribute',
  mixins: [colorMxn],
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
